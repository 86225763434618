import React from "react";
import { Container, ThemeProvider } from "react-bootstrap";

export default function Layout(props: LayoutProps) {
    return <ThemeProvider>
        <header>
            {props.header}
        </header>
        <Container fluid="xxl">
            <main>
                {props.children}
            </main>
            <footer>
                {props.footer}
            </footer>
        </Container>
    </ThemeProvider>;
}

interface LayoutProps {
    header?: React.ReactNode,
    children: React.ReactNode,
    footer?: React.ReactNode
}
